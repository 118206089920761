/* Theme: Geeks */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body{
  font-family: 'Lato', san-serif !important;
  overflow-x: hidden;
}

.bg-brand-1{
  background-color: #6B57FF;
  color: #fff;
}
.text-brand-1{
  color: #6B57FF;
}
.bg-brand-2{
  background-color: #21D789;
  color: #000;
}
.text-brand-2{
  color: #21D789;
}
.text-brand-2:hover{
  color: #21D789 !important;
}
.bg-polygon{
  background-color: #F0E9FC;
  color: #8247E5;
}
.text-polygon{
  color: #8247E5;
}
.bg-is-transparent{
  background-color: rgba(230, 230, 230, 0.75);
}
.bg-dark{
  background-color: #0D0D0D !important;
}
.text-gradient-mix-shade {
  background: -webkit-linear-gradient(#4A52FF, #C678FF, #FE5D75);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-position: left;
}

.list-group{
    max-height: 100vh;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.clickable{
  cursor: pointer;
}

.gradient-color{
  background-color: -webkit-linear-gradient(#4A52FF, #C678FF, #FE5D75);
  color:#fff
}
.twitter-color{
  background-color: #1DA1F2
}
.twitter-color:hover{
  background-color: #1DA1F2
}
.twitter-color:focus{
  background-color: #1DA1F2
}
.opensea-color{
  background-color: #1868B7
}
.opensea-color:hover{
  background-color: #1868B7
}
.opensea-color:focus{
  background-color: #1868B7
}
.metamask-color{
  background-color: #D7C1B3
}
.metamask-color:hover{
  background-color: #D7C1B3
}
.metamask-color:focus{
  background-color: #D7C1B3
}
.rounded-top-3{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-bottom-3{
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.offcanvas-bottom-custom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }

  .nofocus:focus {
    outline: none !important;
  }

  .nofocus:active {
    outline: none !important;
  }

  .rounded-4 {
    border-radius: 20px;
  }
  .card-footer-4 {
      border-bottom-right-radius: 20px !important;
      border-bottom-left-radius: 20px !important;
    }
  .brand-button{
    padding: 1rem 2.5rem !important;
    border-radius: 2rem !important;
  }
  .brand-button-1{
    padding: 1rem 2rem !important;
    border-radius: 1rem !important;
  }
  .button-rounded{
    border-radius: 2rem !important;
  }
  .opacity-75{
    opacity: 0.75 !important;
  }

  .nav-underline .nav-link {
    background: none;
    opacity: 0.5;
    border: 0;
    border-radius: 0.25rem; }

  .nav-underline .nav-line {
    border-bottom: 1px solid #000 ;
  }

  .nav-underline .nav-link.active,
  .nav-underline .show > .nav-link {
    opacity: 1;
    text-decoration: underline;
  }


  .custom-file-container__custom-file__custom-file-control{
    border-radius: 2rem !important;
    justify-content: center !important;
  }
